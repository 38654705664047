import { hu } from "i18n/hu";
import styles from "./Footer.module.scss";

export default function Footer() {
  return (
    <footer className={styles.m}>
      <ul className={styles.menu}>
        {hu.footer.links.map((item => (
          <li>
            <a href={item.url} target={item.target || "_self"}>{item.label}</a>
          </li>
        )))}
      </ul>
      <p>{hu.footer.copyright}</p>
      <div className="mt-5 md:mt-10">
        <a href="https://yco.hu/" target="_blank" rel="noreferrer noopener" className={styles.yco}>
          <svg width="54" height="30" viewBox="0 0 54 30" fill="none"
               xmlns="http://www.w3.org/2000/svg">
            <g className={styles.yco_y}>
              <path fill="#fff" d="m8.666 14.99 4.33-7.5L8.676.01H.017l8.65 14.98z"></path>
              <path fill="#fff" d="M0 30h8.66L25.97.019h-8.66L12.996 7.49l-4.33 7.5L0 30z"></path>
            </g>
            <g className={styles.yco_o}>
              <path fill="#4c4c4c" fill-rule="evenodd" clip-rule="evenodd"
                    d="M38.175 30c8.285 0 15-6.716 15-15 0-8.284-6.715-15-15-15-8.284 0-15 6.716-15 15 0 8.284 6.716 15 15 15zm0-7.5a7.5 7.5 0 1 0 0-15 7.5 7.5 0 0 0 0 15z"></path>
            </g>
            <g className={styles.yco_c}>
              <path fill="#7f7f7f" fill-rule="evenodd" clip-rule="evenodd"
                    d="M39.007 22.454C36.42 26.963 31.56 30 25.987 30c-8.284 0-15-6.716-15-15 0-8.284 6.716-15 15-15 5.572 0 10.433 3.037 13.02 7.546a7.49 7.49 0 0 0-6.926 3.081 7.5 7.5 0 1 0 0 8.746 7.49 7.49 0 0 0 6.926 3.081z"></path>
              <path fill="#a5a5a5" fill-rule="evenodd" clip-rule="evenodd"
                    d="M32.082 28.71a15.062 15.062 0 0 0 6.925-6.256 7.49 7.49 0 0 1-6.926-3.081 7.49 7.49 0 0 1-6.925 3.081c.077.142.194.338.345.571a15.068 15.068 0 0 0 6.58 5.686zm0-27.42a15.061 15.061 0 0 1 6.925 6.256 7.49 7.49 0 0 0-6.926 3.081 7.49 7.49 0 0 0-6.925-3.081s.12-.224.355-.587a15.072 15.072 0 0 1 5.648-5.22c.562-.302.923-.45.923-.45z"></path>
              <path fill="#000" fill-rule="evenodd" clip-rule="evenodd"
                    d="M12.998 7.494A14.931 14.931 0 0 0 10.988 15c0 .442.019.879.056 1.311l2.642-1.525 2.642-1.525-3.33-5.767z"></path>
              <path fill="#000" fill-rule="evenodd" clip-rule="evenodd"
                    d="m16.328 13.26-2.642 1.526-2.642 1.525c.194 2.239.88 4.337 1.948 6.186L25.97.019h-.739c-5.231.26-9.757 3.2-12.233 7.475l3.33 5.767z"></path>
            </g>
          </svg>
        </a>
      </div>
    </footer>
  )
}
